import styles from './Header.module.scss';

export const Header = () => {
  return (
    <div className={styles.header}>
      <div className="container">
        <div className={styles.logo}></div>
      </div>
    </div>
  );
};
