/* eslint no-console: off */
import { useState } from 'react';
import styles from './Game.module.scss';

import { Cards } from './components';
import {
  LOVE,
  KINDNESS,
  TRUST,
  UNDERSTANDING,
  LOYALTY,
  RESPECT,
  values,
} from '@/src/constants';

type TProps = {
  changePage: (s: string) => void;
  style: string;
};

export const Game = ({ changePage, style }: TProps) => {
  const [value, setValue] = useState(LOVE);

  const handleNextValue = () => {
    const activeIndex = Object.keys(values).indexOf(value);

    if (activeIndex === Object.keys(values).length - 1) {
      changePage('final');
    } else {
      setValue(Object.keys(values)[activeIndex + 1]);
    }
  };

  return (
    <div className={styles.game}>
      <div className="container">
        <div className={styles.wrap}>
          {value === LOVE && <Cards value={LOVE} style={style} />}
          {value === KINDNESS && <Cards value={KINDNESS} style={style} />}
          {value === TRUST && <Cards value={TRUST} style={style} />}
          {value === UNDERSTANDING && (
            <Cards value={UNDERSTANDING} style={style} />
          )}
          {value === LOYALTY && <Cards value={LOYALTY} style={style} />}
          {value === RESPECT && <Cards value={RESPECT} style={style} />}
          <button onClick={handleNextValue}>Следующая ценность</button>
        </div>
      </div>
    </div>
  );
};
