import styles from './Choice.module.scss';

type TProps = {
  changePage: (s: string) => void;
  changeStyle: (s: string) => void;
};

export const Choice = ({ changePage, changeStyle }: TProps) => {
  const handleGoNext = (style: string) => {
    changeStyle(style);
    changePage('game');
  };

  return (
    <div className={styles.choice}>
      <div className="container">
        <p>Выбор стиля карточек</p>
        <button onClick={() => handleGoNext('ai')}>AI</button>
        <button onClick={() => handleGoNext('loveis')}>LOVE IS</button>
        <button onClick={() => handleGoNext('words')}>WORDS</button>
      </div>
    </div>
  );
};
