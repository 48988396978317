import styles from './Footer.module.scss';

import logo from '../../images/hh.svg';

export const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className="container">
        <div className={styles.wrap}>
          <div className={styles.left}>
            © ДОМ.РФ<span>|</span>
            <img src={logo} alt="" />
            Бренд-центр 2024
          </div>
          <a href="" target="_blank" className={styles.vacancyLink}>
            Вакансии
          </a>
          <a href="" target="_blank" className={styles.careerLink}>
            Карьера ДОМ.РФ
          </a>
        </div>
      </div>
    </div>
  );
};
