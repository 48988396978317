import styles from './Rules.module.scss';
import classNames from 'classnames';

type TProps = {
  changePage: (s: string) => void;
};

export const Rules = ({ changePage }: TProps) => {
  const handleGoNext = () => {
    changePage('choice');
  };

  return (
    <div className={styles.rules}>
      <div className="container">
        <div className={styles.wrap}>
          <div className={styles.title}>Что нужно делать:</div>
          <div className={styles.list}>
            <div className={styles.wrapper}>
              <div className={classNames(styles.item, styles.item1)}>
                <p>Выбирайте персонажа для&#160;прохождения игры</p>
              </div>
            </div>
            <div className={styles.wrapper}>
              <div className={classNames(styles.item, styles.item2)}>
                <p>Ставьте лайк ценностям, которые вам&#160;ближе</p>
              </div>
            </div>
            <div className={styles.wrapper}>
              <div className={classNames(styles.item, styles.item3)}>
                <p>Заберите свой уникальный стикерпак!</p>
              </div>
            </div>
          </div>
          <button
            className={classNames('button', styles.button)}
            onClick={handleGoNext}
          >
            Начать
          </button>
        </div>
      </div>
    </div>
  );
};
