export const LOVE = 'love';
export const KINDNESS = 'kindness';
export const TRUST = 'trust';
export const UNDERSTANDING = 'understanding';
export const LOYALTY = 'loyalty';
export const RESPECT = 'respect';

export const AI = 'ai';
export const LOVEIS = 'loveis';
export const WORDS = 'words';

export type TValue = {
  id: string;
  title: string;
  texts: TText[];
};

export type TText = {
  id: number;
  text: string;
  author: string;
};

type TValues = {
  [key: string]: TValue;
};

export const values: TValues = {
  love: {
    id: 'love',
    title: 'Любовь это…',
    texts: [
      {
        id: 1,
        text: '…когда ставишь интересы дорогого тебе человека <span>выше своих собственных</span>',
        author: 'Николай, персональный менеджер',
      },
      {
        id: 2,
        text: '…<span>видеть лучшее</span>, принимать настоящее, помогать для&#160;будущего',
        author: 'Виктория, руководитель проектов',
      },
      {
        id: 3,
        text: '…<span>внутренняя тишина</span> и&#160;уверенность',
        author: 'Светлана, руководитель направления',
      },
      {
        id: 4,
        text: '…радоваться тому, что твоя работа <span>приносит пользу</span>',
        author: 'Ирина, главный специалист',
      },
      {
        id: 5,
        text: '…добровольная <span>отдача себя</span> любимому человеку или&#160;делу',
        author: 'Лариса, руководитель направления',
      },
      {
        id: 6,
        text: '…то, что позволяет нам <span>с&#160;радостью</span> утром идти на&#160;работу, а&#160;вечером&#160;– домой!',
        author: 'Наталья, руководитель',
      },
    ],
  },
  kindness: {
    id: 'kindness',
    title: 'Доброта – это…',
    texts: [
      {
        id: 1,
        text: '…уважение чужого <span>права на&#160;ошибку</span>',
        author:
          'Алексей, руководитель направления в&#160;отделе качества данных',
      },
      {
        id: 2,
        text: '…когда даже <span>маленькие поступки</span> делают <nobr>чей-то</nobr> день ярче',
        author: 'Галина, эксперт',
      },
      {
        id: 3,
        text: '…когда ты помогаешь другому человеку и&#160;для&#160;этого <span>не&#160;обязательно быть сильным и&#160;богатым</span>',
        author: 'Ольга, главный специалист',
      },
      {
        id: 4,
        text: '…бескорыстное стремление протянуть <span>руку помощи</span>',
        author: 'Николай, персональный менеджер',
      },
      {
        id: 5,
        text: '…писать рабочие письма так, чтобы <span>их понимали с&#160;первого раза</span>',
        author: 'Екатерина, руководитель направления',
      },
      {
        id: 6,
        text: '…способность списывать ошибки других, <span>не&#160;разрушаясь самому</span>',
        author: 'Анна, ведущий специалист',
      },
    ],
  },
  trust: {
    id: 'trust',
    title: 'Доверие – это…',
    texts: [
      {
        id: 1,
        text: '…когда веришь, что коллеги не&#160;съедят твой обед из&#160;холодильника',
        author: 'Галина, эксперт',
      },
      {
        id: 2,
        text: '…когда веришь, как самому себе',
        author: 'Наталья, главный специалист',
      },
      {
        id: 3,
        text: '…кирпичик, на&#160;котором строятся крепкие отношения',
        author: 'Анастасия, специалист',
      },
      {
        id: 4,
        text: '…рассказывать все, не&#160;таясь и&#160;не&#160;боясь, что тебя потом за&#160;это накажут или&#160;подставят',
        author: 'Михаил, директор КП',
      },
      {
        id: 5,
        text: '…делиться с&#160;коллегами важным на&#160;нытингах и&#160;знать, что тебя не&#160;осудят',
        author: 'Анна, руководитель',
      },
      {
        id: 6,
        text: '…открыть свой расчетный лист рядом с&#160;сидящим коллегой',
        author: 'Лилия, главный специалист',
      },
    ],
  },
  understanding: {
    id: 'understanding',
    title: 'Взаимопонимание – это…',
    texts: [
      {
        id: 1,
        text: '…молча кивнуть в&#160;ответ на&#160;вопросительный взгляд коллеги,&#160;- "а&#160;не&#160;пора&#160;ли?"',
        author: 'Сергей, руководитель',
      },
      {
        id: 2,
        text: '…предложить кофе засыпающему коллеге',
        author: 'Наталья, руководитель направления',
      },
      {
        id: 3,
        text: '…разговор без&#160;слов',
        author: 'Антон, ведущий юрист',
      },
      {
        id: 4,
        text: '…не&#160;спорить, баг или&#160;фича, а&#160;просто закрывать эту задачу',
        author: 'Татьяна, руководитель тестирования',
      },
      {
        id: 5,
        text: '…скидывать одинаковые мемы в&#160;рабочие чаты',
        author: 'Анастасия, главный специалист',
      },
      {
        id: 6,
        text: '…когда друг друга слушаешь, а&#160;главное&#160;- слышишь',
        author: 'Ольга, главный специалист',
      },
    ],
  },
  loyalty: {
    id: 'loyalty',
    title: 'Верность – это…',
    texts: [
      {
        id: 1,
        text: '…нормальное качество для&#160;людей, которые успешно прошли эволюцию с&#160;каменного века',
        author: 'Татьяна, главный специалист',
      },
      {
        id: 2,
        text: '…уважать свой выбор и&#160;не&#160;предавать его',
        author: 'Яна, ведущий специалист',
      },
      {
        id: 3,
        text: '…постоянство в&#160;словах и&#160;обещаниях',
        author: 'Илья, руководитель направления',
      },
      {
        id: 4,
        text: '…единственная валюта, которая никогда не&#160;обесценится',
        author: 'Ольга, главный специалист',
      },
      {
        id: 5,
        text: '…не&#160;фрилансить на&#160;стороне',
        author: 'Анастасия, специалист',
      },
      {
        id: 6,
        text: '…умение при&#160;больших внутренних запросах выбирать и&#160;оставаться с&#160;ним/с&#160;ней',
        author: 'Анна, ведущий специалист',
      },
    ],
  },
  respect: {
    id: 'respect',
    title: 'Уважение – это…',
    texts: [
      {
        id: 1,
        text: '…выслушивать идеи коллег, даже если они выглядят как план по&#160;захвату мира',
        author: 'Галина, эксперт',
      },
      {
        id: 2,
        text: '…возможность быть услышанным в&#160;коллективе',
        author: 'Ксения, ведущий юрист',
      },
      {
        id: 3,
        text: '…не&#160;кричать на&#160;коллег, даже если очень хочется',
        author: 'Людмила, главный специалист по&#160;охране труда',
      },
      {
        id: 4,
        text: '…относиться к&#160;каждому клиенту как к&#160;привилегированному',
        author: 'Екатерина, руководитель направления',
      },
      {
        id: 5,
        text: '…не&#160;писать после рабочего времени',
        author: 'Кумри, персональный менеджер',
      },
      {
        id: 6,
        text: '…когда работодатель без&#160;напоминаний индексирует зарплату',
        author: 'Надежда, главный специалист',
      },
    ],
  },
};
