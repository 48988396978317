import styles from './Home.module.scss';

type TProps = {
  changePage: (s: string) => void;
};

export const Home = ({ changePage }: TProps) => {
  return (
    <div className={styles.home}>
      <div className="container">
        <p>Начальная страница</p>
        <button onClick={() => changePage('rules')}>Дальше</button>
      </div>
    </div>
  );
};
