/* eslint no-console: off */
import { useState } from 'react';
import 'swiper/css';
import styles from './Cards.module.scss';
import classNames from 'classnames';

import { type Swiper as SwiperType } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards, Navigation } from 'swiper/modules';

import { values, TText, WORDS } from '@/src/constants';
import { createText } from '@/src/utils/createText';

import dislike from '../../../images/dislike.svg';
import like from '../../../images/like.svg';
import back from '../../../images/back.svg';

type TProps = {
  value: string;
  style: string;
};

export const Cards = ({ value, style }: TProps) => {
  const [activeCard, setActiveCard] = useState(1);
  const [isLiked, setIsLiked] = useState(0);

  const [swiper, setSwiper] = useState<SwiperType>();

  const handleLike = () => {
    setIsLiked(activeCard);
    if (swiper) {
      setTimeout(() => {
        swiper.slideNext();
      }, 500);
    }
  };

  const handleDislike = () => {
    if (activeCard === isLiked) {
      setIsLiked(0);
    }
    if (swiper) {
      setTimeout(() => {
        swiper.slideNext();
      }, 500);
    }
  };

  const renderSlides = () => {
    return values[value].texts.map((text: TText, index: number) => {
      return (
        <SwiperSlide key={text.id}>
          <div
            className={classNames(
              styles.card,
              styles[
                style !== WORDS ? value + '-' + style + (index + 1) : style
              ],
              {
                [styles.cardIsLiked]: index + 1 === isLiked,
              },
            )}
          >
            <div
              className={styles.title}
              dangerouslySetInnerHTML={createText(values[value].title)}
            ></div>
            {style === WORDS && (
              <div className={styles.slogan}>
                <p dangerouslySetInnerHTML={createText(text.text)}></p>
              </div>
            )}
            <div className={styles.text}>
              {style !== WORDS && (
                <p dangerouslySetInnerHTML={createText(text.text)}></p>
              )}
              <span
                className={styles.author}
                dangerouslySetInnerHTML={createText(text.author)}
              ></span>
            </div>
          </div>
        </SwiperSlide>
      );
    });
  };

  return (
    <div className={styles.cards}>
      <Swiper
        onSwiper={setSwiper}
        modules={[EffectCards, Navigation]}
        effect="cards"
        cardsEffect={{
          perSlideOffset: 8,
          perSlideRotate: 2,
          slideShadows: false,
        }}
        loop={true}
        slidesPerView={'auto'}
        centeredSlides={true}
        speed={600}
        navigation={{
          prevEl: '.toLeft',
        }}
        onSlideChange={() => {
          if (swiper) {
            setActiveCard(swiper.realIndex + 1);
          }
        }}
      >
        {renderSlides()}
      </Swiper>
      <div className={styles.buttons}>
        <button
          className={classNames(styles.button, styles.dislike)}
          onClick={handleDislike}
        >
          <img src={dislike} alt="" />
        </button>
        <button
          className={classNames(styles.button, styles.like, {
            [styles.buttonIsLiked]: activeCard === isLiked,
          })}
          onClick={handleLike}
        >
          <img src={like} alt="" />
        </button>
        <button className={classNames('toLeft', styles.button, styles.back)}>
          <img src={back} alt="" />
        </button>
      </div>
    </div>
  );
};
