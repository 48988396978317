import { useMemo, useState } from 'react';
import { Home } from '@/src/pages/Home';
import { Rules } from '@/src/pages/Rules';
import { Choice } from '@/src/pages/Choice';
import { Game } from '@/src/pages/Game';
import { Final } from '@/src/pages/Final';
import { Header } from '../Header';
import { Footer } from '../Footer';

export const App = () => {
  const [page, setPage] = useState('choice'); // home / rules / choice / game / final

  const [style, setStyle] = useState(''); // ai / loveis / words

  const changePage = (pageName: string) => {
    setPage(pageName);
  };

  const changeStyle = (styleName: string) => {
    setStyle(styleName);
  };

  const renderCurrentPage = useMemo(() => {
    switch (page) {
      case 'home':
        return <Home changePage={changePage} />;
      case 'rules':
        return <Rules changePage={changePage} />;
      case 'choice':
        return <Choice changePage={changePage} changeStyle={changeStyle} />;
      case 'game':
        return <Game changePage={changePage} style={style} />;
      case 'final':
        return <Final changePage={changePage} />;
      default:
        return <div className="error_render">Ошибка состояния приложения!</div>;
    }
  }, [page]);

  return (
    <div className="app">
      <Header />
      {renderCurrentPage}
      <Footer />
    </div>
  );
};
